/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import { Link } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";

export const Button = styled(({ isPrimary, ...rest }) => (
  <Link {...rest}></Link>
))<{ isPrimary?: boolean }>`
  &&& {
    font-size: 20px;
    margin: 20px 30px;
    display: inline-block;
    background: #fff;
    color: ${props => props.theme.colors.text};
    border: 2px solid ${props => props.theme.colors.text};
    border-radius: 100vh;
    padding: 10px 30px;
    font-weight: 700;
    text-transform: uppercase;

    &:hover {
      background: #ddd;
      color: ${props => props.theme.colors.text};
    }

    ${props =>
      props.isPrimary &&
      css`
        background: ${props => props.theme.colors.main};
        border-color: ${props => props.theme.colors.main};
        color: #fff;

        &:hover {
          background: ${props => props.theme.colors.mainHover};
          border-color: ${props => props.theme.colors.mainHover};
          color: #fff;
        }
      `}
  }
`;
