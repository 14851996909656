import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { ProductCategory } from "../shared/use-product-categories";

const Container = styled.div`
  background: #000;
  position: relative;
  z-index: 0;
`;

const ImageContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.2;
`;

const StyledLink = styled(Link)`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 2;

  span {
    font-size: 24px;
    color: #fff;
    transition: all 0.2s;
  }

  &:hover span {
    font-size: 28px;
  }
`;

interface HomeProductProps {
  category: ProductCategory;
}

export const HomeProduct: React.FC<HomeProductProps> = (props) => {
  const { category } = props;
  return (
    <Container>
      <ImageContainer>
        <GatsbyImage
          image={category.coverImage!.gatsbyImageData}
          alt=""
          objectFit="cover"
          style={{ height: "250px" }}
        />
      </ImageContainer>
      <StyledLink to={`/prodotti/${category?.slug}`}>
        <span>{category?.title}</span>
      </StyledLink>
    </Container>
  );
};
