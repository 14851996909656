import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { HomePageNoticeQuery } from "../../graphql-types";
import { Button } from "../components/Button";
import { HomeProduct } from "../components/HomeProduct";
import { Intro } from "../components/Intro";
import { Layout } from "../components/Layout";
import { Section } from "../components/section/Section";
import { SectionHeader } from "../components/section/SectionHeader";
import { useProductCategories } from "../shared/use-product-categories";

const NoticeSection = styled(Section)`
  background: rgb(0, 119, 112);
  background: linear-gradient(
    45deg,
    rgba(0, 119, 112, 1) 0%,
    rgba(0, 137, 134, 1) 35%,
    rgba(0, 179, 133, 1) 100%
  );

  font-size: 20px;
  color: #fff;
  text-align: center;
  font-weight: 400;

  h2 {
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 700;
  }
`;

const HomeSection = styled(Section)`
  font-size: 16px;
  text-align: center;

  p {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: 250px;
  grid-gap: 20px;
`;

const IndexPage: React.FC = () => {
  const productCategories = useProductCategories();

  const noticeQuery: HomePageNoticeQuery = useStaticQuery(graphql`
    query HomePageNotice {
      datoCmsHomepageNotice {
        visible
        title
        message
      }
    }
  `);

  const noticeQueryResults = noticeQuery.datoCmsHomepageNotice!;

  return (
    <Layout>
      <Intro
        title="Benvenuto in Brianzarredi"
        tagLine="I solidi mobili della Brianza a prezzi eccezionali"
      ></Intro>
      {noticeQueryResults.visible && noticeQueryResults.message && (
        <NoticeSection>
          <h2>{noticeQueryResults.title}</h2>
          <p>{noticeQueryResults.message}</p>
        </NoticeSection>
      )}
      <HomeSection>
        <SectionHeader>Chi siamo</SectionHeader>
        <p>
          Da oltre trent&apos;anni Brianzarredi propone{" "}
          <strong>arredamenti componibili</strong> che si adattano alle vostre
          esigenze, e vi offre un servizio personalizzato e completo, dalla{" "}
          <strong>progettazione</strong> al <strong>montaggio</strong>.
        </p>
        <div>
          <Button isPrimary to="/servizi">
            Scopri di più
          </Button>
          <Button to="/lavori/cucine">Lavori realizzati</Button>
        </div>
      </HomeSection>
      <HomeSection withBackground>
        <SectionHeader>Dove siamo</SectionHeader>
        <p>
          Vieni a trovarci nella nostra esposizione in{" "}
          <strong>Via Lorenteggio 72</strong>, vicino a{" "}
          <strong>Piazza Frattini</strong> a <strong>Milano.</strong>
          <br />
          Contattaci per avere maggiori informazioni, per{" "}
          <strong>prendere un appuntamento</strong>, o per richiedere un{" "}
          <strong>preventivo gratuito.</strong>
        </p>
        <div>
          <Button isPrimary to="/dove-siamo">
            Scopri di più
          </Button>
          <Button to="/contatti">Contattaci</Button>
        </div>
      </HomeSection>
      <HomeSection>
        <SectionHeader>Prodotti</SectionHeader>
        <Grid>
          {productCategories.map((category) => (
            <HomeProduct
              key={category!.slug!}
              category={category}
            ></HomeProduct>
          ))}
        </Grid>
      </HomeSection>
    </Layout>
  );
};

export default IndexPage;
